@import '../../../../styles/resources.scss';

.active {
  font-weight: bold;
  color: $black;
}

.navlink {
  font-weight: bold;
  color: $blue;

  &:active,
  &:hover,
  &:visited {
    color: $blue;
  }
}
