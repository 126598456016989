@import '../../../../styles/resources.scss';

.dealerGroupBtn {
  margin-left: 20px;
}

.appliedDealers{
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  width: 480px;
}

.showAppliedDealers{
  font-weight: bold;
  justify-content: space-between;
  margin: 0 0 25px 16px;
  display: flex;
  gap: 20px;

  span{
    border: 3px solid $black!important;
    height: 25px!important;
    width: 25px!important;
    top: -4px;
    &:after{
      border: solid $black!important;
      border-width: 0 2px 2px 0!important;
      height: 11px!important;
      width: 5px!important;
      left:6px!important;
    }
  }
}
