@import '../.././../styles/resources.scss';

.fileUploader {
  position: relative;
  display: inline-block;
  overflow: hidden;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
