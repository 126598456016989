@import '../../../../../../../styles/resources.scss';

.offerCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 350px;
  padding: 17px;
  border: solid 1px $gray-light;
  border-radius: 4px;

  img {
    width: 100%;
    max-height: 146px;
  }

  &Content {
    h2 {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: normal;
    }

    .subtitle {
      width: 200px;
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 10px;
      line-height: 14px;
    }

    .description {
      font-size: 11px;
    }

    .price {
      font-size: 24px;
    }
  }

  .label {
    display: block;
    padding-top: 15px;
    margin-top: 15px;
    border-top: solid 1px $gray-light;
    font-size: 12px;
    color: $red;
    font-weight: bold;
  }
}
