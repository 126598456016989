@import '../../../styles/resources.scss';

.mainLayout {
  min-height: 100%;
}

.titleContainer {
  display: flex;
  align-items: center;
  img {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid $gray-darker;
  }
}

.contentContainer {
  padding-bottom: 50px;
}

.bgColorAlt {
  background-color: $slate;
}

.titleLink {
  color: white;
}
