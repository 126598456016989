@import '../../../../../styles/resources.scss';

.container {
  display: flex;
}

.list {
  width: 270px;
  height: 380px;
  overflow: auto;
  border: 1px solid $black;
  list-style: none;
  padding: 0;

  button {
    width: 100%;
    border: 0;
    background: none;
    text-align: left;
    outline: 0;
    color: $black;
    padding: 5px 8px;
  }

  .active {
    background-color: $gray-dark;
  }
}

.discalimerContainer {
  margin: 20px 0 0 20px;
  width: 550px;

  textarea {
    height: 150px;
  }

  textarea:disabled {
    background-color: $gray-lighter;
  }
}
