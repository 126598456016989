@import '../../../../styles/resources.scss';

.dealersTable {
  @include table(true);
  overflow: hidden;
  table-layout: fixed;

  thead td,
  tbody td {
    display: inline-block;
    width: 130px;

    &:first-child {
      width: 50px;
      text-align: right;
    }

    // region
    &:nth-child(2) {
      width: 95px;
    }

    // dealer code {
    &:nth-child(3) {
      width: 110px;
    }

    // dealer name
    &:nth-child(4) {
      width: 215px;
    }

    // street
    &:nth-child(5) {
      width: 200px;
    }

    // state
    &:nth-child(7) {
      width: 80px;
    }

    // zip code
    &:nth-child(8) {
      width: 100px;
    }
  }

  thead {
    tr {
      display: block;
    }
  }

  tbody {
    display: block;
    width: 100%;
    height: 250px;
    position: relative;
    overflow: auto;
    font-size: 12px;

    tr {
      display: block;
    }
  }
}
