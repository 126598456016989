@import '../../../styles/resources.scss';

.button {
  display: inline-flex;
  padding: 0 32px;
  align-items: center;
  cursor: pointer;
  border: none;
  height: 38px;
  white-space: nowrap;
}

.default {
  background-color: white;
  border: 1px solid $black;
  color: $black;
}

.primary {
  background-color: $red;
  color: white;
  border-bottom: 2px solid $red-dark;

  &:disabled {
    background-color: darken($red, 10%);
    border-color: darken($red-dark, 10%);
    color: darken(white, 10%);
    cursor: default;
  }
}
