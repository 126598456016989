.dealersSearch {
  display: flex;
  align-items: center;
  width: 500px;
  margin-bottom: 16px;

  > * + * {
    margin-left: 24px;
  }
}

.label {
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  width: 230px;
}
