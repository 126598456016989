$black-dark: #2d2d2d !default;
$black: #333 !default;

$electric-blue: #2196f3 !default;
$blue-darker: #096c90 !default;
$blue-dark: #0281b1 !default;
$blue-bold: #006eff !default;
$blue: #09a9e4 !default;
$blue-light: #00baff !default;
$blue-lighter: #e1f0f6 !default;
$sel-check-blue: #c3dce3 !default;
$input-btm-blue: #00a7e6 !default;

$gray-darker: #6c6c6c !default;
$gray-dark: #999 !default;
$gray: #ccc !default;
$gray-light: #e5e5e5 !default;
$gray-lighter: #f5f5f5 !default;

$green-dark: #15aa1f !default;
$green: #16c122 !default;
$green-lightest: #ebfdef !default;

$lime: #88b600 !default;
$lime-light: #c5d98d !default;
$lime-dark: #6d9200 !default;

$navy: #5680ae !default;
$navy-light: #a9bed4 !default;

$orange: #eb881e !default;

$pink: #fbe8e8 !default;

$red-dark: #6a0f0f !default;
$red: #eb0a1e !default;
$red-light: #ef191a !default;
$red-lighter: #ec8b8b !default;
$red-lightest: #ffe2e2 !default;

$slate: #edf0f5 !default;
$slate-dark: #e6e9ee !default;

$teal: #50bfcd !default;
$teal-light: #a8dfe5 !default;
$teal-dark: #4099a4 !default;

$yellow: #ff9900 !default;

$font-family: Arial;
