@import '../../../../styles/resources.scss';

.dealerGroupTable {
  display: flex;
  flex-direction: column;
}

.addBtn {
  background: none;
  border: none;
  margin-top: 20px;
  padding: 0;
  font-size: 11px;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
}

.addBtnIcon {
  font-size: 16px;
  margin-right: 8px;
}

.tableBtn {
  background: none;
  border: none;
  color: $blue;
  cursor: pointer;
}
