@import '../../../../styles/resources.scss';

.offerTabs {
  display: inline-flex;
  list-style: none;
  padding: 0 0 12px 0;

  li:last-child {
    border-right: 1px solid $gray;
  }
}
