
@import '../../../../../styles/resources.scss';

.container{
  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}

.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  width: 100%;
  margin: 30px 0 0 0;
}

.range::-webkit-slider-runnable-track {
  background-color: $slate-dark;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: $red;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

.range::-moz-range-track {
  background-color: $slate-dark;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: $red;
}