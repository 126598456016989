.nameEnabledContainer {
  display: flex;

  > * {
    flex: 0 0 50%;
    padding-right: 24px;
  }
}

.imageContainer {
  display: flex;
  margin-top: 36px;

  > * {
    flex: 0 0 200px;
    padding-right: 24px;
  }

  .imagePreviewContainer {
    flex: 0 0 200px;

    img {
      display: block;
      max-width: 200px;
      max-height: 200px;
      width: auto;
      height: auto;
    }
  }
}
