@import '../../../../../styles/resources.scss';

.container {
  display: flex;
}

.list {
  width: 270px;
  height: 380px;
  overflow: auto;
  border: 1px solid $black;

  list-style: none;
  padding: 0;

  button {
    width: 100%;
    border: 0;
    background: none;
    text-align: left;
    outline: 0;
    color: $black;
    padding: 5px 8px;
  }

  .active {
    background-color: $gray-dark;
  }
}

.imageContainer {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  max-height: 460px;
  overflow: auto;
  row-gap: 35px;

  .imageDiv {
    padding: 10px 30px;
    cursor: pointer;
  }
  .cardWrapper {
    width: 150px;

    .imageWrapper {
      height: 170px;
      text-align: center;
      max-width: 230px;
    }
    .nameWrapper {
      text-align: center;
      padding: 10px 0px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .selectedImage {
    background-color: $gray-light;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
