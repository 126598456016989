.addButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 0;
  margin-right: 20px;
}

.label {
  font-size: 11px;
  margin-left: 8px;
}
