@import '../../../../styles/resources.scss';

.activeContainer {
  padding: 0px 0 32px 0;
  background-color: white;
}

.expiredContainer {
  padding: 32px 0;
}

.offerName {
  width: 150px;
}

.offerCategory {
  a {
    color: $blue !important;
  }
}

.expiredRow {
  .ctas {
    visibility: hidden;
  }
  &:hover {
    .ctas {
      visibility: visible;
    }
  }
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding-bottom: 16px;

  > * {
    flex: 1;
    padding-right: 12px;
  }

  .input {
    font-size: 12px;
  }
}

.arrowIcon {
  color: $blue;
  margin-left: 5px;
  cursor: pointer;
}

.startDate {
  cursor: pointer;
}
