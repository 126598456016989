.buttonGroup {
  display: flex;
  margin-bottom: 16px;

  > * + * {
    margin-left: 16px;
  }
}

.noBottomMargin {
  margin-bottom: 0px;
}
