@import '../../../../styles/resources.scss';

.settingsTable {
  display: block;
  border: 1px solid $gray-darker;

  thead,
  tbody {
    display: block;
  }

  thead {
    tr {
      background-color: $gray-light;
    }

    td {
      text-transform: uppercase;
      color: $gray-darker;
      font-weight: bold;
      font-size: 11px;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: white;
      }

      &:nth-child(odd) {
        background-color: $gray-lighter;
      }
    }

    td {
      font-size: 11px;
      padding: 5px;
    }
  }

  td {
    display: flex;
    align-items: center;
    min-height: 45px;
  }

  tr {
    display: flex;
  }
}
