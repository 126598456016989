@import '../../../styles/resources';

.customDatePicker {
  @include input();
  position: relative;
  display: inline-flex;
  width: auto;

  .error {
    border: 1px solid $red;
  }

  [class*='-wrapper'],
  [class*='__input-container'] {
    height: 100%;
  }

  [class*='__input-container'] {
    input {
      height: 100%;
      text-align: right;
      padding: 1px 24px 0 0;
      border: 0;
      background-color: transparent;
      outline: none;
    }
  }
}

.icon {
  position: absolute;
  right: 14px;
  color: $blue;
}
