@import '../../../../styles/resources.scss';

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $black;
  padding: 10px 20px;
}

.title {
  color: white;
  font-weight: normal;
  font-size: 16px;
}

.closeButton {
  border: none;
  background: transparent;
  cursor: pointer;
  color: white;
  opacity: 0.5;
  font-size: 16px;
}
