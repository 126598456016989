@import '../../../../styles/resources';

.tableContainer {
  border: 1px solid $gray;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background-color: $gray-dark;
  color: white;

  &.collapsable {
    cursor: pointer;
  }
}

.tableTitle {
  font-weight: normal;
  font-size: 13px;
  margin: 0;
  padding: 0 8px;
}

.chevron {
  margin-right: 24px;
}

.table {
  @include table();
  table-layout: fixed;

  thead {
    tr {
      td {
        &:first-child {
          padding-left: 32px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          padding-left: 32px;
        }

        a {
          display: block;
          color: $black;
        }
      }
    }
  }
}
