@import '../../../../styles/resources.scss';

.container {
  width: 800px;
}

.dealerGroupTable {
  @include table(true);
  border: 1px solid $gray-darker;
  overflow: hidden;
  table-layout: fixed;

  thead td,
  tbody td {
    display: inline-block;
    width: 130px;

    // name
    &:first-child {
      width: 250px;
      padding-left: 30px;
    }

    // modified by
    &:nth-child(2) {
      width: 250px;
    }

    // modified date
    &:nth-child(3) {
      width: 200px;
    }

    //action ctas
    &:nth-child(4) {
      width: 90px;
      text-align: right;
    }
  }

  thead {
    tr {
      display: block;
    }
  }

  tbody {
    display: block;
    width: 100%;
    position: relative;
    font-size: 12px;

    tr {
      display: block;
    }
  }
}
