.inputContainer {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.label {
  width: 130px;
  margin-bottom: 8px;
}
