@import '../../../../styles/resources.scss';

.sideNav {
  flex: 0 0 200px;
  list-style: none;
  padding: 0 0 0 0;
  margin: 0 40px 0 0;

  .active,
  a {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 12px;
  }

  .active {
    background-color: $blue;
    color: white;
  }

  a {
    color: $blue;
  }
}
