@import '../../../../styles/resources.scss';

.offerTab {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray;
  border-right: 0;
  height: 25px;
  width: 250px;
  color: $black;
  font-size: 12px;

  a {
    display: flex;
    color: $black;
  }
}

.selectedTab {
  background-color: $gray-light;
  font-weight: bold;
}
