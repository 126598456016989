.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 8px 0;
  margin-bottom: 24px;

  h2 {
    margin: 0;
  }
}
