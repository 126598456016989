@import '../../../styles/resources';

.logoutContainer {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0px;
  text-align: right;
  color: $blue;

  a {
    display: inline-block;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 12px 12px;
    color: $blue;
  }
  svg {
    margin-right: 8px;
  }
}

.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  text-align: right;
}
