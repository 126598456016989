.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 1040;
}

.modalContainer {
  width: 100%;
  height: 100%;
}

.modalDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1030;
}

.dialog {
  display: inline-block;
  position: absolute;
  top: 150px;
  z-index: 1040;
  width: 475px;

  &.medium {
    width: 700px;
  }

  &.large {
    width: 900px;
  }

  &.auto {
    width: auto;
    min-width: 510px;
  }
}

.content {
  margin-bottom: 150px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  outline: 0;
  background-color: white;
}
