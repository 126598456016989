@import '../../../../../styles/variables';

.nameEnabledContainer {
  display: flex;

  > * {
    flex: 0 0 50%;
    padding-right: 24px;
  }
}

.disclaimerTextContainer {
  margin-top: 35px;

  .textarea {
    color: $gray-darker;
    height: 130px;
    width: 350px;
  }
}

.disclaimerModal {
  width: 635px;
}
