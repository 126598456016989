@import '../../../styles/resources.scss';

.dropdownContent {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  background-color: white;
  border: 1px solid $gray;
  border-radius: 3px;
  box-shadow: 0 2px 5px 1px $gray-dark;
  z-index: 10;
}

.alignRight {
  right: 0;
}
