@import '../../../../styles/resources.scss';

.gridCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  border: 1px solid $gray;
  background-color: $gray-lighter;
  user-select: none;

  &:hover {
    background-color: $blue-lighter;

    .offerLabel,
    .offerCount {
      color: $blue;
    }
  }
}

.total {
  grid-area: total;
  background-color: $gray-light;
}

.offerCountPanels {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    'total total total total'
    'dealer regional natinal expired';
}

.offerLabel {
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 3px;
  color: $gray-dark;
}

.offerCount {
  font-size: 24px;
  color: $black;
}
