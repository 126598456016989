@import '../../../../../../../styles/resources.scss';

.offerDetail {
  display: flex;
  flex-direction: column;
  max-width: 365px;

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }

  img {
    height: 100%;
    margin: 20px 0;
    border: 1px solid $gray-light;
  }

  .disclaimer {
    margin: 0;
    font-size: 9px;
    line-height: 13px;
  }
}
