@import '../../../styles/resources.scss';

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  background-color: $gray-lighter;
}

.navList {
  display: flex;
  list-style: none;
  padding: 0;

  > li {
    display: flex;
    align-items: center;
    color: $gray-dark;
    font-size: 12px;

    > span {
      display: inline-block;
      padding: 0 24px;
    }

    // remove left padding on first link
    &:first-child {
      > span {
        padding-left: 0;
      }
    }

    // separator
    &:not(:first-child) {
      &::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 11px;
        background-color: $blue;
      }
    }
  }
}
