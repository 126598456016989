@import '../../../styles/resources.scss';

.checkbox {
  display: inline-flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    width: 16px;
    height: 16px;
  }

  .control {
    display: inline-flex;
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4px;
      top: 1px;
      width: 3px;
      height: 8px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked {
    & + .control:after {
      display: block;
    }
  }

  input:disabled {
    & + .control {
      cursor: default;
    }
  }
}

.label {
  margin-left: 8px;
  font-size: 11px;
}

.default {
  .control {
    background-color: white;
    border: 1px solid $blue-bold;
    border-radius: 3px;

    &:after {
      border: solid $blue-bold;
      border-width: 0 2px 2px 0;
    }
  }

  input:disabled {
    & + .control {
      border-color: lighten($blue-bold, 20%);

      &:after {
        border-color: lighten($blue-bold, 20%);
      }
    }
  }
}

.solid {
  .control {
    background-color: white;
    border: 1px solid $blue;

    &:after {
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }

  input:checked {
    & + .control {
      background-color: $blue;
    }
  }
}
