@import '../../../../../styles/resources.scss';

.addBtn {
  background: none;
  border: none;
  margin-top: 20px;
  padding: 0;
  font-size: 11px;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
}

.addBtnIcon {
  font-size: 16px;
  margin-right: 8px;
}

.imageContainer {
  display: flex;
  align-items: center;
  width: 38px;
  height: 38px;

  img {
    display: block;
    max-width: 38px;
    max-height: 38px;
    width: auto;
    height: auto;
  }
}

.tableBtn {
  background: none;
  border: none;
  color: $blue;
  cursor: pointer;
}
.image {
  td {
    // image name
    &:nth-child(1) {
      flex: 0 0 200px;
      padding-left: 30px;
    }

    // image
    &:nth-child(2) {
      flex: 0 0 125px;
    }

    // status
    &:nth-child(3) {
      flex: 0 0 75px;
    }

    // modified by
    &:nth-child(4) {
      flex: 0 0 175px;
    }

    // modified date
    &:nth-child(5) {
      flex: 0 0 125px;
    }

    // action ctas
    &:nth-child(6) {
      flex: 0 0 100px;
    }
  }
}
