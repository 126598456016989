@import '../../../styles/resources.scss';

.blockUIContainer {
  position: relative;
  min-height: 3em;
}

.blockUI {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: white;
}

.messageContainer {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10001;
}
.loadingIndicator {
  text-align: center;
}
.loadingRing {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $blue transparent transparent transparent;
}
.loadingRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
