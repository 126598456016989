@import '../../../../../styles/resources.scss';

.button {
  font-size: 20px;
  margin-left: 29px;
  border: none;
  background: none;
  cursor: pointer;
}

.buttonItem {
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  color: $gray-darker;
  outline: 0;
  font-weight: bold;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: $blue;
  }
}

.list {
  width: 175px;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 8px;

    &:nth-child(even) {
      background-color: $gray-lighter;
    }
  }
}

.greenIcon {
  color: $green;
}

.orangeIcon {
  color: $orange;
}
