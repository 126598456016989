@import '../../../../../styles/resources';

.container {
  margin-bottom: 60px;
}

.label {
  display: block;
  margin-bottom: 16px;
}

.instructions {
  list-style: none;
  padding: 0;
  color: $gray;
  font-size: 11px;
}

.previewContainer {
  width: 200px;
 
  img {
    width: 100%;
    border: 1px solid $gray;
  }
}
