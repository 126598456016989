@import "styles/resources.scss";

html {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
