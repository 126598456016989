@mixin table($border: false) {
  width: 100%;
  border-collapse: collapse;
  @if $border {
    border: 1px solid $gray;
  }

  thead {
    tr {
      background-color: $gray-light;
      color: $gray-darker;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;

      td {
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: white;
      }
      &:nth-child(even) {
        background-color: $gray-lighter;
      }

      td {
        padding: 14px 10px;
      }
    }
  }
}

@mixin input() {
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 38px;
  border: 0;
  border-bottom: 2px solid $gray;
  background-color: $gray-lighter;

  &:disabled {
    background-color: $gray-light;
  }
}
