@import '../../../../styles/resources.scss';

.link {
  display: inline-flex;
  align-items: center;

  img {
    width: 45px;
    height: 30px;
    cursor: pointer;
  }
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 8px 72px 8px 24px;

    a {
      display: flex;
      color: $gray-darker;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .settingsIcon {
    color: $red;
    margin-right: 8px;
  }
}
