@import '../../../../styles/variables';

.dealerGroupModal {
  width: 950px;

  .dealerGroupName {
    margin: 15px 0;
    width: 350px;
    input {
      width: 350px;
    }
  }
  .errorMessage {
    align-self: center;
    margin-left: 10px;
    margin-top: 10px;
  }

  thead td,
  tbody td {
    &:first-child {
      width: 50px;
      text-align: right;
    }

    // region
    &:nth-child(2) {
      width: 95px;
    }

    // dealer code {
    &:nth-child(3) {
      width: 110px;
    }

    // dealer name
    &:nth-child(4) {
      width: 150px;
    }

    // street
    &:nth-child(5) {
      width: 200px;
    }

    // state
    &:nth-child(7) {
      width: 70px;
    }

    // zip code
    &:nth-child(8) {
      width: 80px;
    }
  }
}
