@import '../../../../../styles/resources.scss';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  display: inline-flex;
  list-style: none;
  padding: 0 0 12px 0;

  li:last-child {
    border-right: 1px solid $gray;
  }
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray;
  border-right: 0;
  height: 25px;
  width: 250px;
  color: $black;
  font-size: 12px;

  a {
    display: flex;
    color: $black;
  }

  button {
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.selectedTab {
  background-color: $gray-light;
  font-weight: bold;
}

.contentWrapper {
  margin-top: 20px;
}
