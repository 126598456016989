@import './styles/resources';

html,
body {
  min-height: 100%;
  height: 100%;
  min-width: 1000px;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

textarea {
  font-family: $font-family;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100%;
  height: 100%;
}

//scrollbar
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: $gray-lighter;
  border: 1px solid $gray-light;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: $gray;
  border: 3px solid transparent;
  background-clip: content-box;
  min-height: 25px;
}
