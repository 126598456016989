.fileUploaderContainer {
  display: flex;

  > * + * {
    margin-left: 16px;
  }

  input {
    width: 350px;
  }
}
