@import '../../../styles/resources';

.toast {
  :global(.Toastify) {
    &__toast {
      padding: 24px;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);

      &--error {
        background: $red-lightest;
        border: 1px solid $red;
        color: $red;
      }

      &--success {
        background: $green-lightest;
        color: $green;
      }
    }

    &__close-button {
      color: $black;
    }

    &__toast-container {
      margin-left: 0; // this centers the toast. idk how
      margin-top: -8px;
    }

    &__toast-body {
      font-size: 14px;
      padding-right: 24px;
    }
  }
}
