$containerWidth: 575px;

.inputContainer {
  width: $containerWidth;
  height: 75px;
}

.listInputContainer {
  width: $containerWidth;
  height: 130px;
}
