.selectSection {
  margin: 32px 0 24px 0;

  .select {
    width: 275px;
    margin-bottom: 16px;
  }
}

.sectionHeader {
  width: 600px;
}

.sectionContent {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 16px 16px;
}
