@import '../../../styles/resources.scss';

.customSelect {
  &[class*='-container'] {
    width: 100%;
  }

  [class*='-control'] {
    border: 0;
    border-bottom: 2px solid $gray;
    border-radius: 0;
    background-color: $gray-lighter;
  }

  [class*='-indicatorSeparator'] {
    display: none;
  }

  [class*='-indicatorContainer'] {
    color: $blue;
  }

  [class*='-menu'] {
    border-radius: 0;
  }

  &.error {
    [class*='-control'] {
      border: 1px solid $red;
    }
  }
}
