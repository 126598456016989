@import '../../../../styles/resources.scss';

.dealerGroupList {
  height: 480px;
  width: 300px;
  border: 1px solid $black;
  overflow: auto;
  margin-top: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 8px 0px 8px 10px;
      cursor: pointer;
    }
  }

  .dealerGroupSelect {
    font-weight: bold;
    background-color: $gray-lighter;
  }
}
