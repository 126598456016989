@import '../../../styles/resources.scss';

.textarea {
  @include input();
  resize: none;
  padding: 8px;
}

.error {
  border: 1px solid $red;
}
