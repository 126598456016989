@import '../../../styles/resources.scss';

.listTextarea {
  display: flex;
  background-color: $gray-dark;
  padding: 8px;
}

.label {
  flex: 0 1 80px;
  &:after {
    content: '*';
    color: $red;
  }
}

.ctas {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 0 1 30px;
}

.textarea {
  flex: 1 1 auto;
  resize: none;
  padding: 8px;
  background-color: $gray-light;

  &:focus {
    background-color: white;
  }
}

.icon {
  cursor: pointer;
}
