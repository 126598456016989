.labelContainer {
  display: flex;
  align-items: center;
  width: 270px;
  margin: 0 30px 16px 0;

  .label {
    width: 130px;
  }
}

.labelContainerVertical {
  .label {
    display: block;
    margin-bottom: 8px;
  }
}

.labelContainer.serviceSpecialsCategory {
  width: 450px;
  .label {
    width: 250px;
  }
}
