@import '../../../../styles/resources.scss';

.reportButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid $gray;
  cursor: pointer;
  color: $blue;
  flex: 0 0 150px;
  height: 200px;
  margin: 0 16px 16px 0;

  &:hover {
    border-color: $blue;
  }
}

.icon {
  display: block;
  font-size: 50px;
  margin-bottom: 16px;
}
