@import '../../../../../styles/resources.scss';

.ctaContainer {
  text-align: right;
  visibility: hidden;
  padding-right: 32px;
  a {
    color: $gray-dark !important;
  }

  svg {
    cursor: pointer;
  }
}
