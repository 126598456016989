.modalBody {
  padding: 20px;
}

.fixHeightLarge {
  height: 550px;
}
.fixHeighMiddle {
  height: 480px;
}
